import React, { useState } from "react"
import "./kanban.css"
import { SelectField } from "../shared/components/selectfield"
import { Button } from "@atlaskit/button/dist/cjs/components/Button"
import { analysisTypes } from "../../analysisTypes"
import { today, toDays, toIta } from "../../shared/dates"
import { mkApi } from "./api"
import { routes } from "../../shared/routing"
import { Link } from "react-router-dom"
import { useStateContext } from "../../shared/state-context"
import { actions } from "./store"

const defaultLanes = [
    {
        status: "NotYetRegistered",
        title: "To Register",
    },
    {
        status: "Registered",
        title: "Registered",
        hasSelection: true,
        actions: [
            {
                name: "Ship",
                options: (data) => data.suppliers,
            },
        ],
    },
    {
        status: "Shipped",
        title: "Shipped",
    },
    {
        status: "Analysed",
        title: "Analysed",
        hasSelection: true,
        actions: [
            {
                name: "Assign",
                options: (data) => data.reporters,
            },
        ],
    },
    {
        status: "Assigned",
        title: "Assigned",
        hasSelection: true,
        actions: [
            {
                name: "Reassign",
                options: (data) => data.reportersWithNone,
            },
        ],
    },
    {
        status: "Done",
        title: "Done",
    },
]

function getSelectedItems(selectedObject) {
    return Object.entries(selectedObject)
        .filter((e) => e[1])
        .map((e) => e[0])
}

function Kanban({
    id = "default",
    lanes = defaultLanes,
    reporters = [],
    suppliers = [],
    analyses,
    filter = {},
    loadAll,
    openModal = () => {},
}) {
    const { state, dispatch } = useStateContext()
    const { selected } = state
    const api = mkApi(dispatch)

    const reportersOptions = reporters.map((r) => ({
        label: r.name,
        value: r.email,
    }))

    const suppliersOptions = suppliers.map((r) => ({
        label: r.name,
        value: r.id,
    }))
    const additionalData = {
        reporters: reportersOptions,
        suppliers: suppliersOptions,
        reportersWithNone: [
            { label: "Unassigned", value: "unassigned" },
            ...reportersOptions,
        ],
    }

    const onLaneAction = (lane, action, reporter) => {
        const selectedItems = getSelectedItems(selected[lane])
        switch (action) {
            case "Assign":
                api.assignReporter(selectedItems, reporter).then(loadAll)
                break
            case "Reassign":
                if (reporter === "unassigned") {
                    api.unassignReporter(selectedItems).then(loadAll)
                } else {
                    api.assignReporter(selectedItems, reporter).then(loadAll)
                }
                break
            case "Ship":
                api.ship(selectedItems, reporter).then(loadAll)
                break
            default:
                console.log("Unknown action", action)
        }
    }

    return (
        <div className={`kanban-container kanban-container-${id}`}>
            {lanes.map((lane) => {
                const items = analyses[lane.status]?.items ?? []
                const allSelected = items.every(
                    (item) => selected[lane.status][item.barcode],
                )
                return (
                    <Lane
                        key={lane.status}
                        lane={lane}
                        items={items}
                        total={
                            analyses[lane.status]?.pagination?.totalItems ?? 0
                        }
                        additionalData={additionalData}
                        selectedItems={selected[lane.status]}
                        onSelectItem={(item, on) =>
                            dispatch(
                                actions.selectItems(lane.status, [item], on),
                            )
                        }
                        onSelectAll={() =>
                            dispatch(
                                actions.selectItems(
                                    lane.status,
                                    items.map((item) => item.barcode),
                                    !allSelected,
                                ),
                            )
                        }
                        onLaneAction={onLaneAction}
                        onLoadMore={() =>
                            api.loadByStatus(
                                {
                                    status: lane.status,
                                    pageNumber:
                                        analyses[lane.status]?.pagination
                                            ?.pageNumber + 1,
                                    pageSize: 10,
                                    sortBy: "supervisor",
                                    ...filter,
                                },
                                true,
                            )
                        }
                        onOpenPage={(url) => openModal(`${url}?modal=true`)}
                    />
                )
            })}
        </div>
    )
}

function Lane({
    lane,
    items = [],
    total = 0,
    additionalData = {},
    selectedItems = {},
    onSelectItem = () => {},
    onSelectAll = () => {},
    onLaneAction = () => {},
    onLoadMore = () => {},
    onOpenPage = () => {},
}) {
    return (
        <div className="lane">
            <div className="lane-header">
                <div className="lane-title">{lane.title}</div>
                <div className="lane-stats">
                    {items.length}/{total}
                </div>
                <div className="lane-header-actions">
                    {lane.hasSelection ? (
                        <a href="#" onClick={onSelectAll}>
                            Select All
                        </a>
                    ) : null}
                </div>
            </div>
            <div className="lane-actions">
                {lane.actions
                    ? lane.actions.map((action) => (
                          <Action
                              key={action.name}
                              lane={lane}
                              isEnabled={
                                  getSelectedItems(selectedItems).length > 0
                              }
                              onAction={(action, data) =>
                                  onLaneAction(lane.status, action, data)
                              }
                              action={{
                                  ...action,
                                  options: action.options(additionalData),
                              }}
                          />
                      ))
                    : null}
            </div>
            <div className="lane-body">
                {items.map((item, i) => (
                    <Card
                        key={i}
                        item={item}
                        type={lane.status}
                        selected={selectedItems[item.barcode] ?? false}
                        onSelect={onSelectItem}
                        onOpen={onOpenPage}
                    />
                ))}
            </div>
            <div className="lane-footer">
                {items.length < total ? (
                    <Button onClick={onLoadMore}>Load more</Button>
                ) : null}
            </div>
        </div>
    )
}

function Action({ action, onAction, isEnabled = true }) {
    const [selectedOption, setSelectedOption] = useState(null)
    return (
        <>
            <div className="action-options">
                <SelectField
                    options={action.options}
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e.target.value)}
                />
            </div>
            <div className="action-button">
                <Button
                    appearance="primary"
                    isDisabled={!selectedOption || !isEnabled}
                    onClick={() => onAction(action.name, selectedOption)}>
                    {action.name}
                </Button>
            </div>
        </>
    )
}

function getAnalysisType(item) {
    const analysisType = analysisTypes.find(
        (x) => x.value === item.analysisType,
    )
    const subType = item.analysisSubType
    if (analysisType) {
        if (analysisType.value === "MacroBiota" && subType === "AllGut") {
            return "All GUT"
        }
        if (
            analysisType.value === "Epigene" &&
            subType &&
            subType !== "Unknown"
        ) {
            return analysisType.label + " " + subType
        }
        return analysisType.label
    }
    return ""
}

function getDueStatus(dueDate) {
    if (!dueDate) {
        return ""
    }

    const due = toIta(dueDate)
    const diff = toDays(today, due)
    if (diff < 0) return "overdue"
    if (diff <= 2) return "coupleofdays"
    if (diff <= 5) return "week"
    return "morethanaweek"
}

function getDoneStatus(doneDate, dueDate) {
    const due = toIta(dueDate)
    const done = toIta(doneDate)
    const diff = toDays(done, due)

    if (diff < 0) return "overdue"
    return "ontime"
}

function getDueDays(dueDate) {
    if (!dueDate) {
        return ""
    }

    const due = toIta(dueDate)
    const diff = toDays(today, due)
    if (diff < 0) return "Overdue"
    if (diff === 0) return "Today"
    if (diff === 1) return "1 day"
    return `${diff} days`
}

function getDateTooltip(date) {
    if (date) return toIta(date)
    return ""
}

function getDueBadge(dueDate) {
    return (
        <span
            data-tooltip={getDateTooltip(dueDate)}
            className={`barcode-duestatus barcode-duestatus-${getDueStatus(
                dueDate,
            )}`}>
            {getDueDays(dueDate)}
        </span>
    )
}

function getDoneBadge(doneDate, dueDate) {
    return (
        <span
            className={`barcode-duestatus barcode-duestatus-${getDoneStatus(
                doneDate,
                dueDate,
            )}`}>
            {getDoneDate(doneDate)}
        </span>
    )
}

function getDoneDate(doneDate) {
    if (!doneDate) {
        return ""
    }

    return toIta(doneDate)
}

function AnalysisType({ item }) {
    return (
        <div className="card-data-item card-data-analysis-type">
            {getAnalysisType(item)}
        </div>
    )
}

function Patient({ item }) {
    return (
        <div className="card-data-item card-data-patient">
            {item.patientFirstName}&nbsp;{item.patientLastName}
        </div>
    )
}

function Reporter({ item }) {
    return (
        <div className="card-data-item card-data-reporter">
            <span className="reporter-label">Reporter: </span>
            {item.reporterName}
        </div>
    )
}

function Supplier({ item }) {
    return (
        <div className="card-data-item card-data-supplier">
            <span className="reporter-label">Supplier: </span>
            {item.supplierName}
        </div>
    )
}

function EmptyRow() {
    return <div className="card-data-item"></div>
}

function DueDate({ item }) {
    return (
        <div className="due-date-container">
            <span className="due-label">Due: </span>
            {getDueBadge(item.dueDate)}
        </div>
    )
}

function DoneDate({ item }) {
    return (
        <div className="done-date-container">
            <span className="done-label">Done: </span>
            {getDoneBadge(item.doneDate, item.dueDate)}
        </div>
    )
}

const dataExtractors = {
    NotYetRegistered: (item) => <AnalysisType item={item} />,
    Registered: (item) => (
        <>
            <AnalysisType item={item} />
            <Patient item={item} />
            <EmptyRow />
            <DueDate item={item} />
        </>
    ),
    Shipped: (item) => (
        <>
            <AnalysisType item={item} />
            <Patient item={item} />
            <Supplier item={item} />
            <DueDate item={item} />
        </>
    ),
    Analysed: (item) => (
        <>
            <AnalysisType item={item} />
            <Patient item={item} />
            <Supplier item={item} />
            <DueDate item={item} />
        </>
    ),
    Assigned: (item) => (
        <>
            <AnalysisType item={item} />
            <Patient item={item} />
            <Reporter item={item} />
            <DueDate item={item} />
        </>
    ),
    Done: (item) => (
        <>
            <AnalysisType item={item} />
            <Patient item={item} />
            <Reporter item={item} />
            <DoneDate item={item} />
        </>
    ),
}

const cardStyles = {
    NotYetRegistered: () => "",
    Registered: (item) => getDueStatus(item.dueDate),
    Shipped: (item) => getDueStatus(item.dueDate),
    Analysed: (item) => getDueStatus(item.dueDate),
    Assigned: (item) => getDueStatus(item.dueDate),
    Done: (item) => getDoneStatus(item.doneDate, item.dueDate),
}

function openRegistrationModal(item, openPage) {
    const url = routes.geneticAnalyses.register.buildUrl(
        item.barcode,
        item.analysisType,
    )
    openPage(url)
}

function RegisterAction({ item, openPage }) {
    return (
        <div className="register-action">
            <Link
                className="ml-2"
                onClick={(e) => {
                    e.preventDefault()
                    openRegistrationModal(item, openPage)
                }}
                to="">
                +
            </Link>
        </div>
    )
}

function SelectAction({ item, status, toggleStatus = () => {} }) {
    return (
        <div
            className={`select-action select-action-${
                status ? "selected" : "unselected"
            }`}
            onClick={() => toggleStatus(item.barcode, !status)}></div>
    )
}

function getCardTitle(item) {
    return (
        <>
            <Link
                to={{
                    pathname: routes.geneticAnalyses.edit.buildUrl(
                        item.barcode,
                    ),
                }}
                target="_blank">
                {item.barcode}
            </Link>
            {item.referral ? (
                <span>
                    {" "}
                    -{" "}
                    <Link
                        to={{
                            pathname:
                                routes.referrals.index.routes.edit.buildUrl(
                                    item.referral,
                                ),
                        }}
                        target="_blank">
                        {item.referral}
                    </Link>
                </span>
            ) : null}
        </>
    )
}

const cardActions = {
    NotYetRegistered: (data) => (
        <RegisterAction item={data.item} openPage={data.onOpen} />
    ),
    Registered: (data) => (
        <SelectAction
            item={data.item}
            status={data.selected}
            toggleStatus={data.onSelect}
        />
    ),
    Shipped: () => null,
    Analysed: (data) => (
        <SelectAction
            item={data.item}
            status={data.selected}
            toggleStatus={data.onSelect}
        />
    ),
    Assigned: (data) => (
        <SelectAction
            item={data.item}
            status={data.selected}
            toggleStatus={data.onSelect}
        />
    ),
    Done: () => null,
}

function Card({ item, type, selected, onSelect, onOpen }) {
    const style = cardStyles[type](item)
    return (
        <div className={`kanban-card kanban-card-${type} kanban-card-${style}`}>
            <div className="card-header">
                <div className="card-title">{getCardTitle(item)}</div>
                <div className="card-actions">
                    {cardActions[type]({ item, selected, onSelect, onOpen })}
                </div>
            </div>
            <div className="card-data">{dataExtractors[type](item)}</div>
        </div>
    )
}

export default Kanban
