import React from "react"
import { withRouter } from "react-router"
import { useStateContext } from "../../shared/state-context"
import { mkApi } from "./api"
import { routes } from "../../shared/routing"
import { supplierSchema as schema } from "./schemas"
import SupplierForm from "./form"

export const AddSupplier = ({ history }) => {
    const { dispatch } = useStateContext()
    const api = mkApi(dispatch)
    const emptySupplier = {
        name: "",
        email: "",
        pec: "",
        fiscalCode: "",
        phone: "",
        address: "",
        city: "",
        province: "",
        cap: "",
        piva: "",
        sdi: "",
    }

    const update = (supplier) => {
        api.add(supplier).then(() => {
            history.push(routes.suppliers.index.url)
        })
    }

    return (
        <SupplierForm
            isEdit={false}
            initialValues={emptySupplier}
            schema={schema}
            onSubmit={update}
            onCancel={() => history.goBack()}
            formProps={{ title: "Add Supplier", primaryText: "Save" }}
        />
    )
}

export default withRouter(AddSupplier)
