import * as yup from "yup"

export const supplierSchema = yup.object().shape(
    {
        name: yup.string().required(),
        email: yup.string().email().required(),
        pec: yup.string().email().optional().nullable(),
        fiscalCode: yup.string().optional().nullable(),
        phone: yup.string().optional().nullable(),
        address: yup.string().optional().nullable(),
        city: yup.string().optional().nullable(),
        province: yup.string().optional().nullable(),
        cap: yup
            .string()
            .optional()
            .nullable()
            .when("cap", {
                is: (value) => value?.length,
                then: (rule) => rule.length(5),
            }),
        piva: yup
            .string()
            .optional()
            .nullable()
            .when("piva", {
                is: (value) => value?.length,
                then: (rule) => rule.length(11),
            }),
        sdi: yup
            .string()
            .optional()
            .nullable()
            .when("sdi", {
                is: (value) => value?.length,
                then: (rule) => rule.length(7),
            }),
    },
    [
        ["cap", "cap"],
        ["piva", "piva"],
        ["sdi", "sdi"],
    ],
)
