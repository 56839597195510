import { match } from "../../shared/store"

export const initialState = {
    isLoading: true,
    suppliers: [],
}

export const reducer = (state, action) => {
    return match(action)
        .case("LOAD-SUPPLIERS", handleLoadSuppliers)
        .case("EDIT-SUPPLIER", handleEditSupplier)
        .case("ADD-SUPPLIER", handleAddSupplier)
        .apply(state)
}

export const actions = {
    loadAll(data) {
        return { type: "LOAD-SUPPLIERS", data }
    },
    edit(data) {
        return { type: "EDIT-SUPPLIER", data }
    },
    add(data, reload) {
        return { type: "ADD-SUPPLIER", data, reload }
    },
}

const handleLoadSuppliers = (state, action) => {
    const { items, pagination } = action.data
    return {
        isLoading: false,
        suppliers: items,
        pattern: "",
        ...pagination,
    }
}

const handleEditSupplier = (state, action) => {
    const { suppliers } = state
    const supplier = action.data

    const newSuppliers = suppliers.map((x) =>
        x.id === supplier.id ? supplier : x,
    )
    return {
        suppliers: newSuppliers,
    }
}

const handleAddSupplier = (state, action) => {
    const { suppliers } = state

    const newSuppliers = [action.data, ...suppliers]
    return action.reload
        ? { suppliers: [] }
        : {
              suppliers: newSuppliers,
          }
}
