import { analysisTypes } from "../../analysisTypes.js"
import { SelectField } from "../shared/components/selectfield.jsx"
import React from "react"
import AutocompleteSelectField from "../shared/components/autocomplete-selectedfield.jsx"
import InlineSearch from "../shared/components/inline-search.jsx"

const SupervisorDashboardFilter = ({
    type,
    referral,
    reporter,
    supplier,
    reporters,
    suppliers,
    onFilter,
    onChangeSearch,
    loadReferrals,
}) => {
    const filterAnalysisTypes = [
        {
            label: "All Analysis Type",
            value: "",
        },
        ...analysisTypes,
    ]
    const allReferrals = [
        {
            label: "All Referrals",
            value: "",
        },
    ]
    const allSuppliers = [
        { label: "All Suppliers", value: "" },
        ...suppliers.map((s) => ({ label: s.name, value: s.id })),
    ]
    const allReporters = [
        {
            label: "All Reporters",
            value: "",
        },
        ...reporters.map((r) => ({ label: r.name, value: r.email })),
    ]

    function setTypeFilter(evt) {
        onFilter("type", evt.target.value)
    }
    function setReferralFilter(evt) {
        onFilter("referral", evt.target)
    }
    function setReporterFilter(evt) {
        onFilter("reporter", evt.target)
    }
    function setSupplierFilter(evt) {
        onFilter("supplier", evt.target)
    }

    const referralsOptions = referral
        ? [referral, ...allReferrals]
        : allReferrals
    function autocompleteReferrals(pattern) {
        if (!pattern) {
            return Promise.resolve(referralsOptions)
        }
        return loadReferrals(pattern).then((data) => {
            return data.items.map((item) => ({
                value: item.code,
                label: `(${item.code}) - ${item.name}`,
            }))
        })
    }

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <SelectField
                width="col-2"
                options={allReporters}
                value={reporter?.value || ""}
                onChange={setReporterFilter}
            />
            <AutocompleteSelectField
                width="col-2"
                options={referralsOptions}
                value={referral?.value || ""}
                load={autocompleteReferrals}
                onChange={setReferralFilter}
            />
            <SelectField
                width="col-2"
                options={allSuppliers}
                value={supplier?.value || ""}
                onChange={setSupplierFilter}
            />
            <SelectField
                width="col-2"
                options={filterAnalysisTypes}
                value={type || ""}
                onChange={setTypeFilter}
            />
            <InlineSearch onChange={onChangeSearch} className="col-4" />
        </div>
    )
}

export default SupervisorDashboardFilter
