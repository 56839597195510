import React from "react"
import Form from "../shared/components/form"
import TextField from "../shared/components/textfield"
import useFormist from "react-formist"

const SupplierForm = ({
    initialValues,
    schema,
    formProps,
    onSubmit,
    onCancel,
}) => {
    const formist = useFormist(initialValues, { schema, onSubmit })
    return (
        <>
            <Form
                buttonsOnTop
                {...formProps}
                {...formist.getFormProps()}
                onCancel={onCancel}>
                <div className="form-container-separator">
                    <div className="form-row">
                        <TextField
                            label="Name"
                            width="col"
                            {...formist.getFieldProps("name")}
                        />
                    </div>
                    <div className="form-row">
                        <TextField
                            label="Email"
                            width="col"
                            {...formist.getFieldProps("email")}
                        />
                    </div>
                    <div className="form-row">
                        <TextField
                            label="Pec"
                            width="col"
                            {...formist.getFieldProps("pec")}
                        />
                    </div>
                    <div className="form-row">
                        <TextField
                            label="Fiscal Code"
                            width="col"
                            {...formist.getFieldProps("fiscalCode")}
                        />
                    </div>
                    <div className="form-row">
                        <TextField
                            label="Phone"
                            width="col"
                            {...formist.getFieldProps("phone")}
                        />
                    </div>
                    <div className="form-row">
                        <TextField
                            label="Address"
                            width="col"
                            {...formist.getFieldProps("address")}
                        />
                    </div>
                    <div className="form-row">
                        <TextField
                            label="City"
                            width="col"
                            {...formist.getFieldProps("city")}
                        />
                    </div>
                    <div className="form-row">
                        <TextField
                            label="Province"
                            width="col"
                            {...formist.getFieldProps("province")}
                        />
                    </div>
                    <div className="form-row">
                        <TextField
                            label="CAP"
                            width="col"
                            {...formist.getFieldProps("cap")}
                        />
                    </div>
                    <div className="form-row">
                        <TextField
                            label="PIVA"
                            width="col"
                            {...formist.getFieldProps("piva")}
                        />
                    </div>
                    <div className="form-row">
                        <TextField
                            label="SDI"
                            width="col"
                            {...formist.getFieldProps("sdi")}
                        />
                    </div>
                </div>
            </Form>
        </>
    )
}

export default SupplierForm
