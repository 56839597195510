import React, { useEffect } from "react"
import { range } from "ramda"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import SectionHeader from "../shared/components/section-header"
import PaginatedTable from "../shared/components/paginated-table"
import { useStateContext } from "../../shared/state-context"
import { mkApi } from "./api"
import { routes } from "../../shared/routing"
import Button from "@atlaskit/button"
import ButtonBar from "../shared/components/button-bar"

const SuppliersList = (props) => {
    const { state, dispatch } = useStateContext()
    const api = mkApi(dispatch)
    const { suppliers, isLoading, pageNumber, totalPages } = state
    const { history } = props
    const pages = range(1, totalPages + 1)

    useEffect(() => {
        api.loadAll(pageNumber)
    }, [])

    const onChangePage = (e, pageNumber) => {
        api.loadAll(pageNumber)
    }

    const addClickHandler = (e) => {
        e.preventDefault()
        history.push(routes.suppliers.index.routes.add.url)
    }

    const head = {
        cells: [
            {
                key: "name",
                content: "Name",
            },
            {
                key: "email",
                content: "Email",
            },
        ],
    }

    const mkEditUrl = (item) => {
        const { id } = item
        return routes.suppliers.index.routes.edit.buildUrl(id)
    }

    const mkRows = (items) => {
        return items.map((item, index) => {
            return {
                key: `row-${index}-${item.id}`,
                cells: [
                    {
                        content: (
                            <Link
                                to={{
                                    pathname: mkEditUrl(item),
                                    state: item,
                                }}>
                                {item.name}
                            </Link>
                        ),
                    },
                    { content: item.email },
                ],
            }
        })
    }

    return (
        <>
            <SectionHeader title="Suppliers list" />
            <div className="row">
                <div className="form-group col-4 btn-alongside-center">
                    <Button appearance="primary" onClick={addClickHandler}>
                        Add Supplier
                    </Button>
                </div>
            </div>
            <PaginatedTable
                head={head}
                rows={mkRows(suppliers)}
                isLoading={isLoading}
                pages={pages}
                pageNumber={pageNumber}
                onChangePage={onChangePage}
            />

            <ButtonBar>
                <Button appearance="primary" onClick={addClickHandler}>
                    Add Supplier
                </Button>
            </ButtonBar>
        </>
    )
}

export default withRouter(SuppliersList)
