import { apis } from "../../shared/apis.js"
import { actions } from "./store.js"

export function mkApi(dispatch) {
    return {
        loadByStatus(
            {
                pageNumber,
                pageSize,
                sortBy,
                type,
                pattern,
                status,
                referral,
                reporter,
                supplier,
            },
            append = false,
        ) {
            return apis.geneticAnalyses
                .loadByStatus(
                    pageNumber,
                    pageSize,
                    sortBy,
                    `${type ?? ""}:${referral?.value || ""}:${
                        reporter?.value || ""
                    }:${supplier?.value || ""}`,
                    pattern,
                    status,
                )
                .then((data) => {
                    dispatch(
                        actions.loadByStatus({
                            ...data,
                            status,
                            append,
                            type,
                            referral,
                            reporter,
                            supplier,
                            pattern,
                        }),
                    )
                })
                .catch((error) => {
                    dispatch(actions.error(error))
                })
        },
        loadReporters() {
            return apis.users.loadByRole("reporter")
        },
        loadReferrals(pattern) {
            return apis.referrals.search(pattern, 1, 1000)
        },
        loadAllSuppliers() {
            return apis.suppliers.loadAll(1, 1000)
        },
        assignReporter(barcodes, reporter) {
            return apis.geneticAnalyses.assignReporter(barcodes, reporter)
        },
        ship(barcodes, supplier) {
            return apis.geneticAnalyses.ship(barcodes, supplier)
        },
        unassignReporter(barcodes) {
            return apis.geneticAnalyses.unassignReporter(barcodes)
        },
    }
}
