import React from "react"
import { createRoot } from "react-dom/client"
import Layout from "./views/shared/layout.ng"
import { withSecurityContext } from "./shared/security/security-context"
import User from "./shared/security/user"
import Version from "./version"

window.__serverUrl__ =
    import.meta.env.VITE_APP_BASE_ADDRESS || "http://localhost:5000"
window.__sentry_dsn__ = import.meta.env.VITE_SENTRY_DSN || ""

const urlParams = new URLSearchParams(location.search)
const pageOnly = urlParams.has("modal") && urlParams.get("modal") === "true"

const App = withSecurityContext(() => {
    return (
        <div className={pageOnly ? "app-in-modal" : ""}>
            <Layout pageOnly={pageOnly}>
                <Version />
                <User />
            </Layout>
        </div>
    )
})

const root = createRoot(document.getElementById("app-container"))
root.render(<App />)
