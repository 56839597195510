import React, { useState, useCallback } from "react"

function debounce(func, wait) {
    let timeout
    return function (...args) {
        const context = this
        clearTimeout(timeout)
        timeout = setTimeout(() => func.apply(context, args), wait)
    }
}

const InlineSearch = (
    { onChange, pattern = "", delay = 500, className = "" },
    ref,
) => {
    const [value, setValue] = useState(pattern)

    const debouncedOnChange = useCallback(debounce(onChange, delay), [
        onChange,
        delay,
    ])

    const innerOnChange = (e) => {
        setValue(e.target.value)
        if (e.target.value.length >= 2 || e.target.value.length === 0)
            debouncedOnChange(e)
    }

    return (
        <div className={`form-group ${className}`}>
            <input
                ref={(ref.current !== undefined && ref) || undefined}
                type="text"
                defaultValue={value}
                className="form-control"
                placeholder="search..."
                onChange={innerOnChange}
            />
        </div>
    )
}

export default InlineSearch
