import React from "react"
import { useStateContext } from "../../shared/state-context"
import { mkApi } from "./api"
import { routes } from "../../shared/routing"
import { supplierSchema as schema } from "./schemas"
import SupplierForm from "./form"
import { head } from "ramda"

import withFetch from "../shared/components/fetch"

export const Edit = ({ data, history }) => {
    const { dispatch } = useStateContext()
    const api = mkApi(dispatch)

    const update = (supplier) => {
        api.update(supplier).then(() => {
            history.push(routes.suppliers.index.url)
        })
    }

    const [supplier] = data

    return (
        <SupplierForm
            isEdit={true}
            initialValues={supplier}
            schema={schema}
            onSubmit={update}
            onCancel={() => history.goBack()}
            formProps={{ title: "Edit Supplier", primaryText: "Save" }}
        />
    )
}

const loadData = ({ api, id }) => {
    return api.load(id)
}

const fromState = (state, id) => {
    return head(state.suppliers.filter((item) => item.id === id))
}

export default withFetch(Edit, mkApi(), loadData, useStateContext, fromState)
