import { apis } from "../../shared/apis"
import { actions } from "./store"

export function mkApi(dispatch) {
    return {
        loadAll(pageNumber, pageSize) {
            return apis.suppliers
                .loadAll(pageNumber, pageSize)
                .then((suppliers) => dispatch(actions.loadAll(suppliers)))
        },
        load(id) {
            return apis.suppliers.load(id)
        },
        update(value, reload = false) {
            const { id, ...body } = value
            return apis.suppliers
                .updateOne(id, body)
                .then(() => dispatch(actions["edit"](value, reload)))
        },
        add(value, reload = false) {
            return apis.suppliers
                .addOne(value)
                .then(() => dispatch(actions["add"](value, reload)))
        },
    }
}
