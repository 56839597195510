import React, { useEffect, useState } from "react"
import { range } from "ramda"
import { Link } from "react-router-dom"
import SectionHeader from "../shared/components/section-header"
import StatusLink from "../shared/components/status-link"
import ErrorLink from "../shared/components/error-link"
import { withRouter } from "react-router"
import { useStateContext, withStateContext } from "../../shared/state-context"
import { initialState, reducer } from "./store"
import { mkApi } from "./api"
import Filter from "./filter"
import { routes } from "../../shared/routing"
import Button from "@atlaskit/button"
import DropdownMenu, { DropdownItemGroup } from "@atlaskit/dropdown-menu"
import ButtonBar from "../shared/components/button-bar"
import PaginatedTable from "../shared/components/paginated-table"
import { getItem } from "../../shared/security/storage"
import InlineSearch from "../shared/components/inline-search"
import { error, success } from "../../shared/notifications"
import { download } from "../../shared/ajax"
import { analysisTypes } from "../../analysisTypes"
import { hasPDF, useInProgress } from "./pdf-link"
import {
    notRegisteredMenuItem,
    recreatePDFMenuItem,
    reimportMenuItem,
    removeBarcodeMenuItem,
    markAsDoneMenuItem,
    updateBarcodeMenuItem,
    assignReporterMenuItem,
} from "./actions"
import Uploading from "./uploading"
import RemoveBarcode from "./remove-barcode"
import UpdateBarcode from "./update-barcode"
import AssignReporter from "./assign-reporter"
import { toIta } from "../../shared/dates"
import ButtonGroupSelection from "../shared/components/ButtonGroupSelection"
import PrintMenu from "./print-menu"
import PDFMenu from "./pdf-menu"
import EmailMenu from "./email-menu"
import MarkAsDoneBarcode from "./mark-as-done-barcode"
import { isAssignable, isComplete } from "../geneticanalyses/schemas"

function getAnalysisType(type, subType, hasLabResults, hasExtraLabResults) {
    const analysisType = analysisTypes.filter((a) => a.value === type)[0]
    if (analysisType) {
        if (analysisType.value === "MacroBiota" && subType === "AllGut") {
            return (
                <div style={{ display: "flex", alignContent: "center" }}>
                    <span>All GUT</span>
                    {hasLabResults && (
                        <span className="icon-loaded icon-loaded-gut">B</span>
                    )}
                    {hasExtraLabResults && (
                        <span className="icon-loaded icon-loaded-allgut">
                            O
                        </span>
                    )}
                </div>
            )
        }
        if (
            analysisType.value === "Epigene" &&
            subType &&
            subType !== "Unknown"
        ) {
            return analysisType.label + " " + subType
        }
        return analysisType.label
    }
    return "Unknown"
}

const knownErrors = [
    {
        regex: /^unexpected end of stream/i,
        error: "Required fields missing!",
    },
    {
        regex: /^During a bulk write not all items was written: 0 of 0/i,
        error: "No recognized genes, check in the Genes page that the required genes have been included",
    },
]

const matchKnownErrors = (error) => {
    return knownErrors.reduce((match, current) => {
        if (error.match(current.regex)) {
            return current.error
        }
        return match
    }, error)
}

const parseError = (msg) => {
    const searchError = msg.match(
        /^error at.*\(line ([0-9]+), column ([0-9]+)*\):\s*(.*?)$/i,
    )
    if (searchError) {
        const error = searchError[3]
        return `Error in line ${searchError[1]}, column ${
            searchError[2]
        }: ${matchKnownErrors(error)}`
    }
    return msg
}

export const showError = (e) => {
    let ex = e
    if (typeof e === "string") {
        try {
            ex = JSON.parse(e)
        } catch (e) {
            ex = {
                Message: "Error posting file",
                StackTrace: "",
            }
        }
    }
    if (ex && ex.Message) {
        error(parseError(ex.Message), {
            dismiss: false,
        })
        console.error(ex.StackTrace)
    }
}

function getStatusIcon(item) {
    const { status } = item
    return status.toLowerCase()
}

function getStatusLabel(item) {
    const { status } = item
    if (status === "NotYetRegistered") return "To Register"
    return status
}

function selectLastReportSentDate(item) {
    const emails = item.reportEmails
    if (Object.keys(emails).length === 0) return ""

    const sentDates = Object.keys(emails)
        .map((key) => emails[key].sentDate)
        .map((x) => new Date(x))

    const latestDate = new Date(Math.max(...sentDates))
    return toIta(latestDate)
}

function isEditable(item) {
    return (
        item.status.toLowerCase() !== "notyetregistered" &&
        item.status.toLowerCase() !== "uploading"
    )
}

function Dashboard(props) {
    const user = getItem("user")
    const { state, dispatch } = useStateContext()
    const {
        analyses,
        isLoading,
        pageNumber,
        totalPages,
        pattern,
        sortField,
        type,
        template,
        layout,
        currentPageSize,
    } = state
    const api = mkApi(dispatch)
    const { history } = props
    const pages = range(1, totalPages + 1)

    const [removingBarcode, setRemovingBarcode] = useState(null)
    const [barcodeData, setBarcodeData] = useState(null)
    const [updatingBarcode, setUpdatingBarcode] = useState(null)
    const [updatingBarcodeStatus, setUpdatingBarcodeStatus] = useState(null)
    const [assigningReporter, setAssigningReporter] = useState(null)
    const [assigningReporterBarcodes, setAssigningReporterBarcodes] =
        useState(null)

    const [uploading, setUploading] = useState(false)
    const [downloading, setDownloading] = useState(null)

    const [templates, setTemplates] = useState([])
    const [layouts, setLayouts] = useState([])
    const [reporters, setReporters] = useState([])

    const [doneBarcode, setDoneBarcode] = useState(null)
    const [selectedBarcodes, setSelectedBarcodes] = useState({})

    const fetchData = ({
        page = pageNumber,
        search = pattern,
        typeFilter = type,
        templateFilter = template,
        layoutFilter = layout,
        sortBy = sortField,
        pageSize = currentPageSize,
    }) =>
        api.load({
            pageNumber: page,
            pageSize: pageSize,
            sortBy: sortBy,
            type: typeFilter,
            template: templateFilter,
            layout: layoutFilter,
            pattern: search.length >= 2 ? search : null,
        })

    const onChangePage = (e, page) => {
        setSelectedBarcodes({})
        fetchData({ page })
    }

    const onChangePageSize = (size) => {
        setSelectedBarcodes({})
        fetchData({ pageSize: size })
    }

    const onChangeSearch = (e) => {
        setSelectedBarcodes({})
        const search = e.target.value
        fetchData({ page: 0, search })
    }

    function onFilter(filterType, value) {
        setSelectedBarcodes({})
        filterType === "type"
            ? fetchData({
                  page: 0,
                  search: pattern,
                  typeFilter: value,
                  templateFilter: "",
                  layoutFilter: "",
              })
            : filterType === "template"
            ? fetchData({
                  page: 0,
                  search: pattern,
                  typeFilter: type,
                  templateFilter: value,
              })
            : fetchData({
                  page: 0,
                  search: pattern,
                  typeFilter: type,
                  templateFilter: template,
                  layoutFilter: value,
              })
    }

    useEffect(() => {
        fetchData({})
        api.loadTemplates().then(setTemplates)
        api.loadLayouts().then(setLayouts)
        api.loadReporters().then(setReporters)
    }, [])

    useInProgress(analyses, () =>
        fetchData({
            page: pageNumber,
            search: pattern,
            typeFilter: type,
            templateFilter: template,
            layoutFilter: layout,
            sortBy: sortField,
        }),
    )

    const allAssignable = analyses.filter(isAssignable)

    const allBarcodesSelected =
        allAssignable.length > 0 &&
        allAssignable.every((a) => selectedBarcodes[a.barcode])

    function toggleAllBarcodes(e) {
        const checked = e.target.checked
        if (checked) {
            const newSelectedBarcodes = analyses.reduce((res, analysis) => {
                if (isAssignable(analysis)) {
                    return { ...res, [analysis.barcode]: true }
                }
                return res
            }, {})
            setSelectedBarcodes(newSelectedBarcodes)
        } else {
            setSelectedBarcodes({})
        }

        return false
    }

    const head = {
        cells: [
            {
                key: "barcode",
                content: (
                    <>
                        <input
                            type="checkbox"
                            style={{ marginLeft: -20, marginRight: 5 }}
                            checked={allBarcodesSelected}
                            onClick={toggleAllBarcodes}
                        />
                        <span>Barcode</span>
                    </>
                ),
            },
            {
                key: "referral",
                content: "Referral",
            },
            {
                key: "patient",
                content: "Patient",
                width: 15,
            },
            {
                key: "status",
                content: "Status",
            },
            {
                key: "lastModifiedDate",
                content: "Modified",
            },
            {
                key: "registeredDate",
                content: "Registered",
            },
            {
                key: "reportSentDate",
                content: "Report Sent",
            },
            {
                key: "type",
                content: "Type",
                width: 10,
            },
            {
                key: "reporter",
                content: "Reporter",
            },
            { key: "report-show", content: "Report", width: 3 },
            { key: "report-print", width: 4 },
            { key: "pdf", content: "PDF", width: 4 },
            { key: "email", content: "Report Email" },
            { key: "actions" },
        ],
    }

    const mkRows = (items, layouts) => {
        return items.map((item, index) => {
            const sendableLayouts = (item.layoutIds || []).filter((l) =>
                hasPDF(item, l),
            )
            return {
                key: `row-${index}-${item.id}`,
                cells: [
                    {
                        content: isEditable(item) ? (
                            <>
                                <input
                                    type="checkbox"
                                    checked={!!selectedBarcodes[item.barcode]}
                                    onChange={(e) => {
                                        setSelectedBarcodes({
                                            ...selectedBarcodes,
                                            [item.barcode]: e.target.checked,
                                        })
                                    }}
                                    style={{
                                        marginLeft: -20,
                                        marginRight: 5,
                                        visibility: isAssignable(item)
                                            ? "visible"
                                            : "hidden",
                                    }}
                                />
                                <Link
                                    to={{
                                        pathname:
                                            routes.geneticAnalyses.edit.buildUrl(
                                                item.barcode,
                                            ),
                                    }}>
                                    {item.barcode}
                                </Link>
                            </>
                        ) : (
                            <span>{item.barcode}</span>
                        ),
                    },
                    {
                        content: item.referral,
                    },
                    {
                        content: `${item.patientFirstName || ""} ${
                            item.patientLastName || ""
                        }`,
                    },
                    {
                        content:
                            item.status === "Errored" ? (
                                <ErrorLink
                                    item={item}
                                    expectedStatus="errored"
                                    linkText="Error"
                                    error={matchKnownErrors(item.errorMessage)}
                                />
                            ) : (
                                <span
                                    className={`barcode-status barcode-status-${getStatusIcon(
                                        item,
                                    )}`}>
                                    {getStatusLabel(item)}
                                </span>
                            ),
                    },
                    {
                        content:
                            item.lastModifiedDate &&
                            toIta(item.lastModifiedDate),
                    },
                    {
                        content:
                            item.registeredDate && toIta(item.registeredDate),
                    },
                    {
                        content: selectLastReportSentDate(item),
                    },
                    {
                        content: getAnalysisType(
                            item.analysisType,
                            item.analysisSubType,
                            item.hasLabResults,
                            item.hasExtraLabResults,
                        ),
                    },
                    {
                        content: item.reporterName,
                    },
                    {
                        content: (
                            <>
                                <div>
                                    <StatusLink
                                        item={item}
                                        withLink={isComplete}
                                        linkText="Show"
                                        to={{
                                            pathname:
                                                routes.reports.show.buildUrl(
                                                    item.barcode,
                                                ),
                                        }}
                                    />
                                </div>
                            </>
                        ),
                    },
                    {
                        content: (
                            <PrintMenu
                                layouts={item.layoutIds}
                                allLayouts={layouts}
                                barcode={item.barcode}
                                token={user.token}
                                status={item.status}
                            />
                        ),
                    },
                    {
                        content: (
                            <PDFMenu
                                layouts={item.layoutIds}
                                allLayouts={layouts}
                                barcode={item.barcode}
                                status={item.status}
                                pdf={item.pdf}
                                downloading={downloading}
                                onCreate={createPDF}
                                onDownload={downloadPDF}
                            />
                        ),
                    },
                    {
                        content: (
                            <EmailMenu
                                layouts={sendableLayouts}
                                allLayouts={layouts}
                                barcode={item.barcode}
                                emails={item.reportEmails}
                                rules={item.sendRules}
                                attachmentRules={item.attachmentRules}
                                onSend={sendEmail}
                            />
                        ),
                    },
                    {
                        content: (
                            <DropdownMenu
                                trigger="Actions"
                                triggerType="button">
                                <DropdownItemGroup>
                                    {notRegisteredMenuItem(item)}
                                    {reimportMenuItem(
                                        item,
                                        reimportAnalysisHandler,
                                    )}
                                    {recreatePDFMenuItem(
                                        item,
                                        recreatePDFHandler,
                                    )}
                                    {updateBarcodeMenuItem(
                                        item,
                                        updateBarcodeHandler,
                                    )}
                                    {removeBarcodeMenuItem(
                                        item,
                                        removeBarcodeHandler,
                                    )}
                                    {markAsDoneMenuItem(
                                        item,
                                        markAsDoneHandler,
                                    )}
                                    {assignReporterMenuItem(
                                        item,
                                        assignReporterHandler,
                                    )}
                                </DropdownItemGroup>
                            </DropdownMenu>
                        ),
                    },
                ],
            }
        })
    }

    function createPDF(barcode, layout) {
        api.pdf(barcode, layout)
            .then(() => {
                success("PDF is under construction...")
                fetchData({})
            })
            .catch((e) => {
                error(e.Message)
            })
    }

    function downloadPDF(barcode, layout, reportMode) {
        setDownloading(barcode)
        const url = api.downloadPDF(barcode, layout, reportMode)
        download(url, barcode, "pdf")
            .catch((e) => showError(e))
            .finally(() => setDownloading(null))
    }

    function sendEmail(
        barcode,
        layout,
        to,
        patientAttachmentRule,
        referralAttachmentRule,
        callback,
    ) {
        api.sendEmail(
            barcode,
            layout,
            to,
            patientAttachmentRule,
            referralAttachmentRule,
        )
            .then(() => {
                success("Report successfully sent by email!")
            })
            .catch((e) => {
                showError(e)
            })
            .finally(() => {
                if (callback) callback()
                fetchData({})
            })
    }

    const assignReporterHandler = (item) => (e) => {
        e.preventDefault()
        assignReportersHandler([item])
    }

    const assignReporterToBarcodes = (e) => {
        e.preventDefault()
        setAssigningReporterBarcodes(getSelectedBarcodes())
        setAssigningReporter(null)
    }

    const assignReportersHandler = (items) => {
        const barcodes = items.map((i) => i.barcode)
        setAssigningReporterBarcodes(barcodes)
        setAssigningReporter(items.length === 1 ? items[0].reporter : null)
    }

    const cancelAssignReporter = () => {
        setAssigningReporterBarcodes(null)
        setAssigningReporter(null)
    }

    const updateBarcodeHandler = (item) => (e) => {
        e.preventDefault()
        setUpdatingBarcodeStatus(item.status.toLowerCase())
        setUpdatingBarcode(item.barcode)
    }

    const cancelUpdateBarcode = () => {
        setUpdatingBarcodeStatus(null)
        setUpdatingBarcode(null)
    }

    const confirmUpdateBarcode = (newBarcode) => {
        if (updatingBarcodeStatus === "notyetregistered") {
            api.fixAnalysisBarcode(updatingBarcode, newBarcode)
                .then(() => {
                    success("Barcode changed!")
                    fetchData({})
                })
                .catch((e) => {
                    error(e.Message)
                })
                .finally(() => {
                    cancelUpdateBarcode()
                })
        } else {
            api.updateBarcode(updatingBarcode, newBarcode)
                .then(() => {
                    success("Barcode changed!")
                    fetchData({})
                })
                .catch((e) => {
                    error(e.Message)
                })
                .finally(() => {
                    cancelUpdateBarcode()
                })
        }
    }

    const confirmAssignReporter = (reporter) => {
        if (reporter) {
            api.assignReporter(assigningReporterBarcodes, reporter)
                .then(() => {
                    success("Reporter assigned!")
                    setSelectedBarcodes({})
                    fetchData({})
                })
                .catch((e) => {
                    error(e.Message)
                })
                .finally(() => {
                    cancelAssignReporter()
                })
        } else {
            api.unassignReporter(assigningReporterBarcodes)
                .then(() => {
                    success("Reporter assigned!")
                    setSelectedBarcodes({})
                    fetchData({})
                })
                .catch((e) => {
                    error(e.Message)
                })
                .finally(() => {
                    cancelAssignReporter()
                })
        }
    }

    const removeBarcodeHandler = (item) => (e) => {
        e.preventDefault()
        setRemovingBarcode(item.barcode)
    }

    const cancelRemoveBarcode = () => {
        setBarcodeData(null)
        setRemovingBarcode(null)
    }

    const confirmRemoveBarcode = () => {
        api.removeBarcode(removingBarcode, !!barcodeData)
            .then((result) => {
                if (
                    !barcodeData &&
                    (result.patientData ||
                        result.dnaAnalysis ||
                        result.macroBiotaAnalysis)
                ) {
                    setBarcodeData(result)
                } else {
                    success("Barcode removed!")
                    cancelRemoveBarcode()
                    fetchData({})
                }
            })
            .catch((e) => {
                showError(e)
            })
    }

    const markAsDoneHandler = (item) => (e) => {
        e.preventDefault()
        setDoneBarcode(item.barcode)
    }

    const confirmMarkAsDone = () => {
        api.markAsDone(doneBarcode)
            .then(() => {
                success("Barcode marked as done!")
                fetchData({})
            })
            .catch((e) => {
                showError(e.message)
            })
            .finally(() => {
                cancelMarkAsDone()
            })
    }

    const cancelMarkAsDone = () => {
        setDoneBarcode(null)
    }

    const reimportAnalysisHandler = (item) => (e) => {
        e.preventDefault()
        setUploading(true)
        api.reimport(item.barcode)
            .then(() => {
                setUploading(false)
                success("Analysis successfully reimported!")
                fetchData({})
            })
            .catch((e) => {
                showError(e)
                setUploading(false)
            })
    }

    const recreatePDFHandler = (item) => (e) => {
        e.preventDefault()
        api.allpdf(item.barcode)
            .then(() => {
                success("PDF is under construction...")
                fetchData({})
            })
            .catch((e) => {
                error(e.Message)
            })
    }

    const registerAnalysisHandler = (e) => {
        e.preventDefault()
        history.push(routes.geneticAnalyses.add.url)
    }

    const removeBarcodeDataList = (
        barcodeData
            ? [
                  barcodeData.patientData
                      ? "Patient description, diseases, drugs"
                      : "",
                  barcodeData.dnaAnalysis ? "DNA analyses results" : "",
                  barcodeData.macroBiotaAnalysis
                      ? "Macrobiota analyses results"
                      : "",
              ]
            : []
    )
        .filter((w) => w !== "")
        .join(", ")

    const removeBarcodeWarning = barcodeData
        ? `Barcode ${removingBarcode} has some data associated (${removeBarcodeDataList}). Please confirm you are aware that this data is going to be removed too!`
        : `Are you sure you want to remove the barcode ${removingBarcode}?`

    const sortButtons = [
        { value: "modifiedDate", label: "Modified" },
        { value: "registeredDate", label: "Registered" },
        { value: "assignable", label: "Assignable" },
    ]

    const onClickSort = (btn) => {
        fetchData({
            page: 0,
            search: pattern,
            typeFilter: type,
            templateFilter: template,
            layoutFilter: layout,
            sortBy: btn.value,
        })
    }

    const filterTemplates = templates ? templates.items : null
    const filterLayouts = layouts ? layouts.items : null

    function getSelectedBarcodes() {
        return Object.entries(selectedBarcodes)
            .filter((e) => e[1])
            .map((e) => e[0])
    }

    return uploading ? (
        <Uploading />
    ) : (
        <div>
            <SectionHeader title="Dashboard" />
            <div className="group-sections-container">
                <div className="row">
                    <div className="col-4">
                        <InlineSearch onChange={onChangeSearch} />
                    </div>
                    <div className="form-group col-2 btn-alongside-center">
                        <Button
                            appearance="primary"
                            onClick={registerAnalysisHandler}>
                            Register Analysis
                        </Button>
                        <Button
                            className="ml-4"
                            appearance="primary"
                            isDisabled={getSelectedBarcodes().length === 0}
                            onClick={assignReporterToBarcodes}>
                            Assign Reporter
                        </Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-auto">
                        <ButtonGroupSelection
                            buttons={sortButtons}
                            onClickHandler={onClickSort}></ButtonGroupSelection>
                    </div>
                    <div className="col-sm-auto">
                        <Filter
                            type={type}
                            template={template}
                            layout={layout}
                            onFilter={onFilter}
                            templates={filterTemplates}
                            layouts={filterLayouts}
                        />
                    </div>
                </div>
            </div>
            <hr />
            <PaginatedTable
                head={head}
                rows={mkRows(analyses, layouts ? layouts.items : [])}
                isLoading={isLoading}
                pages={pages}
                pageNumber={pageNumber}
                onChangePage={onChangePage}
                onChangePageSize={onChangePageSize}
                pageSize={currentPageSize}
                pageSizeOptions={[15, 30, 60, 120]}
            />
            <ButtonBar>
                <Button appearance="primary" onClick={registerAnalysisHandler}>
                    Register Analysis
                </Button>
                <Button
                    appearance="primary"
                    isDisabled={getSelectedBarcodes().length === 0}
                    onClick={assignReporterToBarcodes}>
                    Assign Reporter
                </Button>
            </ButtonBar>
            <RemoveBarcode
                enabled={removingBarcode}
                onConfirm={confirmRemoveBarcode}
                onCancel={cancelRemoveBarcode}
                warningMessage={removeBarcodeWarning}
            />
            <UpdateBarcode
                enabled={!!updatingBarcode}
                onConfirm={confirmUpdateBarcode}
                onCancel={cancelUpdateBarcode}
                barcode={updatingBarcode}
            />
            <MarkAsDoneBarcode
                enabled={!!doneBarcode}
                onConfirm={confirmMarkAsDone}
                onCancel={cancelMarkAsDone}
                warningMessage={`Are you sure you want to mark barcode ${doneBarcode} as done?`}
            />
            <AssignReporter
                enabled={!!assigningReporterBarcodes}
                onConfirm={confirmAssignReporter}
                onCancel={cancelAssignReporter}
                barcodes={assigningReporterBarcodes}
                reporter={assigningReporter}
                reporters={reporters?.items || []}
            />
        </div>
    )
}

export default withStateContext(withRouter(Dashboard), initialState, reducer)
