import React from "react"
import { Link } from "react-router-dom"

const StatusLink = (props) => {
    const {
        item,
        withLink = () => true,
        linkText,
        to,
        target,
        style = {},
    } = props
    if (withLink(item))
        return (
            <Link style={style} to={to} target={target}>
                {linkText}
            </Link>
        )
    return <span style={style}>{linkText}</span>
}

export default StatusLink
