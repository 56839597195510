import { match } from "../../shared/store"

export const statuses = [
    "NotYetRegistered",
    "Registered",
    "Shipped",
    "Analysed",
    "Assigned",
    "Done",
]

export const initialState = {
    analyses: {},
    isLoading: true,
    error: null,
    type: "",
    referral: "",
    patient: "",
    pattern: "",
    reporter: "",
    supplier: "",
    selected: statuses.reduce((acc, status) => {
        return { ...acc, [status]: {} }
    }, {}),
}

export const reducer = (state, action) => {
    return match(action)
        .case("FETCH-BYSTATUS-SUCCESS", handleFetchByStatusSuccess)
        .case("FETCH-ERROR", handleFetchError)
        .case("SELECT-ITEMS", handleSelectItems)
        .apply(state)
}

export const actions = {
    loadByStatus(data) {
        return { type: "FETCH-BYSTATUS-SUCCESS", data }
    },
    error(error) {
        return { type: "FETCH-ERROR", error }
    },
    selectItems(lane, items, status) {
        return { type: "SELECT-ITEMS", data: { lane, items, status } }
    },
}

const handleFetchByStatusSuccess = (state, action) => {
    const {
        status,
        items,
        pagination,
        append = false,
        reporter,
        referral,
        supplier,
        type,
        pattern,
    } = action.data

    if (append) {
        return {
            ...state,
            analyses: {
                ...state.analyses,
                [status]: {
                    items: [...state.analyses[status].items, ...items],
                    pagination,
                },
            },
            reporter: reporter || "",
            referral: referral || "",
            supplier: supplier || "",
            type: type || "",
            pattern: pattern || "",
        }
    }
    return {
        ...state,
        analyses: {
            ...state.analyses,
            [status]: {
                items,
                pagination,
            },
        },
        selected: {
            ...state.selected,
            [status]: {},
        },
        reporter: reporter || "",
        referral: referral || "",
        supplier: supplier || "",
        type: type || "",
        pattern: pattern || "",
    }
}

const handleSelectItems = (state, action) => {
    const { lane, items, status } = action.data
    const newSelected = items.reduce(
        (acc, item) => {
            return {
                ...acc,
                [item]: status,
            }
        },
        { ...state.selected[lane] },
    )
    return {
        ...state,
        selected: {
            ...state.selected,
            [lane]: newSelected,
        },
    }
}

const handleFetchError = (state, action) => {
    const { error } = action
    return {
        ...state,
        isLoading: false,
        analyses: {},
        error,
        selected: initialState.selected,
    }
}
